"use client";

import { format } from "date-fns";
import { Minus, Plus } from "lucide-react";
import Link from "next/link";
import * as React from "react";
import type { Person, Update as UpdateType, UpdatesMeta } from "types";

import { AccessCheck } from "@/components/access-check";
import AuthorTooltip from "@/components/author-tooltip";
import { ExaminePlusLink } from "@/components/cta";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import parse from "@/components/html-parser";
import { Loading } from "@/components/icons/loading";
import { LockBadge } from "@/components/lock";
import { LoginLink } from "@/components/login-link";
import SaleWrapper from "@/components/sale-wrapper";
import Trial from "@/components/trial";
import { Alert } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ToastAction } from "@/components/ui/toast";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { toast } from "@/components/ui/use-toast";
import { percentOff } from "@/lib/data/sales";
import { useUpdates } from "@/lib/hooks/use-updates";
import { cn, formatTiming } from "@/lib/utils";

type FilterType = "all" | "major" | "minor" | "correction";

type UpdateFilter = {
	name: string;
	type: FilterType;
};

interface AuthorListProps {
	authors?: Person[];
	title?: string;
}

interface SinglePageUpdateProps extends React.HTMLAttributes<HTMLDivElement> {
	updates: UpdateType[];
	showFilters?: boolean;
	meta?: UpdatesMeta;
	expertSlug?: string;
	showPageName?: boolean;
	showAuthorList?: boolean;
}

const AuthorList = ({ authors, title }: AuthorListProps) => {
	if (!authors?.length) return null;

	return (
		<div>
			<p className="mb-1 mt-3 pb-0 text-[17px] leading-[26px] text-[#231f20]">{title}</p>

			<div className="flex flex-wrap">
				{authors?.map((author, index) => (
					<Tooltip key={author.name}>
						<TooltipTrigger>
							{author.has_expert_page && author.slug ? (
								<Link
									href={`/experts/${author.slug}/`}
									rel="noreferrer"
									className="mb-1 mt-0 cursor-pointer pb-0 text-sm leading-5 text-[#767474]"
								>
									{index !== 0 ? <>&nbsp;</> : null}
									{index !== 0 ? authors.length - 1 === index && <>& &nbsp;</> : null}
									<span className={cn(author.about && "hover:underline")}>{author.name}</span>
									{authors.length > 1 &&
									authors.length - 1 !== index &&
									authors.length - 2 !== index
										? ","
										: null}
								</Link>
							) : (
								<div className="mb-1 mt-0 cursor-pointer pb-0 text-sm leading-5 text-[#767474]">
									{index !== 0 ? <>&nbsp;</> : null}
									{index !== 0 ? authors.length - 1 === index && <>& &nbsp;</> : null}
									<span className={cn(author.about && "hover:underline")}>{author.name}</span>
									{authors.length > 1 &&
									authors.length - 1 !== index &&
									authors.length - 2 !== index
										? ","
										: null}
								</div>
							)}
						</TooltipTrigger>
						{author?.about && (
							<TooltipContent className="max-w-xs" variant="overview">
								<AuthorTooltip author={author} showImage />
							</TooltipContent>
						)}
					</Tooltip>
				))}
			</div>
		</div>
	);
};

const SinglePageUpdate = ({
	updates: data,
	meta,
	showFilters = true,
	expertSlug,
	showPageName,
	showAuthorList = true,
	...props
}: SinglePageUpdateProps) => {
	const { updates, isLoading, hasMore, loadMoreUpdates } = useUpdates({
		initialData: data,
		meta,
		expertSlug,
		pageSize: 5,
		onError: () => {
			toast({
				title: "Error!",
				description: "Error loading updates!",
				action: <ToastAction altText="Try again">Try again</ToastAction>,
				variant: "error",
			});
		},
	});
	const [types, setTypes] = React.useState<string[]>([]);

	const filters: UpdateFilter[] = [
		{ name: "All Updates", type: "all" },
		{ name: "Major Updates", type: "major" },
		{ name: "Minor Updates", type: "minor" },
		{ name: "Correction Updates", type: "correction" },
	];

	const filterUpdates = (type: FilterType) => {
		if (type === "all") {
			setTypes([]);
			return;
		}

		if (!types?.includes(type)) {
			setTypes([type]);
		}
	};

	const filteredUpdates = React.useMemo(() => {
		if (types.length) {
			return updates.filter((update) => types.includes(update.type));
		}
		return updates;
	}, [types, updates]);

	return (
		<section className="pt-8" {...props}>
			{showFilters && (
				<div className="mb-6 flex flex-wrap items-center justify-center gap-1.5 pb-4 sm:justify-end">
					{filters.map((filter, index) => (
						<button
							key={index}
							type="button"
							onClick={() => filterUpdates(filter.type)}
							className={cn(
								"flex w-full items-center justify-center rounded-md px-4 py-2 text-sm font-semibold transition duration-200 hover:bg-primary hover:text-white sm:w-auto",
								(!types?.length && filter.type === "all") || types?.includes(filter.type)
									? "bg-primary text-white"
									: "text-primary"
							)}
						>
							<span className="ml-2">{filter.name}</span>
						</button>
					))}
				</div>
			)}

			<section className="my-5">
				{filteredUpdates?.map(
					(
						{
							id,
							date,
							type,
							notes,
							writers,
							editors,
							reviewers,
							detailed_note,
							basic_note,
							content,
						},
						index
					) => (
						<article
							className="listing-post relative my-0 flex max-w-[662px] cursor-default"
							key={id}
						>
							<div
								className={cn(
									"shrink-0 grow-0 basis-px bg-primary",
									index === 0 && "mt-1.5",
									index === updates?.length - 1 && "mb-6"
								)}
							/>
							<div
								className={cn(
									"absolute left-[-5px] top-1.5 box-border flex size-3 items-center justify-center rounded-[50%] border-2 border-solid border-primary",
									index == 0 ? "bg-primary" : "bg-white"
								)}
							>
								<div className="inner" />
							</div>
							<div className="flex-[0_1_100%] pb-8 pl-8 text-gray-900">
								<div className="pt-0.5">
									<Tooltip>
										<TooltipTrigger>
											<span className="cursor-default">{formatTiming({ date })}</span>
										</TooltipTrigger>
										<TooltipContent>
											<p className="font-semibold">
												{new Date(date).toLocaleString("en-US", {
													dateStyle: "medium",
													timeStyle: "short",
												})}
											</p>
										</TooltipContent>
									</Tooltip>
								</div>
								<h1 className="mt-3 flex flex-wrap items-center gap-2 pb-1.5 align-middle text-xl font-semibold">
									{showPageName ? (
										<>
											{content?.name || notes ? (
												<Link
													href={content?.url || ""}
													className="inline text-primary hover:underline [&>*]:inline"
													key={id}
												>
													<span>{content?.name}</span>
													<span>&nbsp;-&nbsp;</span>
													{parse(notes)}
												</Link>
											) : null}
										</>
									) : (
										parse(notes, {})
									)}

									<div className="flex items-center overflow-hidden">
										<Badge
											size="xs"
											className={cn(
												type === "minor" && "bg-examine-purple-200",
												type === "correction" && "bg-green-500 text-white",
												type === "major" && "bg-examine-purple-500 text-white"
											)}
										>
											{type}
										</Badge>
									</div>
								</h1>
								<section className="content mt-3 text-base font-medium text-gray-600 [&>ul]:list-disc [&>ul]:pl-4">
									<AccessCheck
										feature={["study_summaries", "examine_database", "supplement_guides"]}
										cta={parse(detailed_note)}
									>
										{parse(basic_note)}
										<br />
										{detailed_note ? (
											<Alert variant="info" className="flex gap-2 px-4">
												<span>
													<LockBadge size={10} />
												</span>
												<div>
													Detailed update notes are provided for Examine+ members.{" "}
													<LoginLink className="text-examine-purple-400 hover:underline">
														Log in
													</LoginLink>
													<FreeTrialWrapper cta=" to read the full update notes">
														{" "}
														or{" "}
														<SaleWrapper
															cta={
																<ExaminePlusLink
																	trackDetails={{
																		trackFor: "trial",
																		location: "Updates section on Homepage",
																		label: "Try Examine+ free for 7 days",
																	}}
																	className="text-examine-purple-400 hover:underline"
																>
																	try Examine+ free for <Trial />
																</ExaminePlusLink>
															}
														>
															<ExaminePlusLink
																trackDetails={{
																	trackFor: "sale",
																	location: "Updates section on Homepage",
																	label: `Get Examine+ for ${percentOff} off`,
																}}
																className="text-examine-purple-400 hover:underline"
															>
																Get Examine+ for {percentOff} off{" "}
															</ExaminePlusLink>
														</SaleWrapper>
													</FreeTrialWrapper>
													.
												</div>
											</Alert>
										) : null}
									</AccessCheck>
								</section>
								{showAuthorList && (
									<div>
										<AuthorList authors={writers} title="Research written by" />
										<AuthorList authors={editors} title="Edited by" />
										<AuthorList authors={reviewers} title="Reviewed by" />
									</div>
								)}
							</div>
						</article>
					)
				)}
				{meta ? (
					<div className="my-6 flex justify-center">
						<Button
							type="button"
							variant="outline"
							disabled={isLoading || !hasMore}
							className="flex w-full max-w-sm items-center justify-center px-10 py-3 font-semibold"
							onClick={loadMoreUpdates}
						>
							{isLoading ? <Loading /> : "Load More"}
						</Button>
					</div>
				) : null}
			</section>
		</section>
	);
};

const Update = ({
	id,
	date,
	type,
	note,
	notes,
	content,
	detailed_note,
	basic_note,
	headline,
	headline_link,
	category,
	study_summaries,
	view = "full",
	index,
}: UpdateType) => {
	const [open, setOpen] = React.useState(view === "full" || index === 0);

	return (
		<Collapsible open={open} onOpenChange={view == "preview" ? setOpen : () => {}}>
			<article className="xxl:max-w-4xl relative my-0 flex max-w-3xl snap-center scroll-smooth">
				<div
					className={cn(
						"max-[110px] mr-8 mt-3.5 hidden shrink-0 grow-0 basis-28 text-right",
						view === "full" ? "sm:block" : ""
					)}
				>
					<Tooltip>
						<TooltipTrigger>
							<span className="cursor-default">
								{formatTiming({
									date,
									pattern: category == "study_summary" ? "MMMM d, yyyy" : "MMMM d, yyyy h:mma",
								})}
							</span>
						</TooltipTrigger>
						<TooltipContent>
							<p className="font-semibold">
								{format(
									new Date(date),
									category == "study_summary" ? "MMMM d, yyyy" : "MMMM d, yyyy h:mma"
								)}
							</p>
						</TooltipContent>
					</Tooltip>
				</div>
				<div className="shrink-0 grow-0 basis-0.5 bg-gray-300" />
				{view === "full" ? (
					<div className="absolute -left-2 top-4 box-border flex size-5 items-center justify-center rounded-[50%] border-[5px] border-solid border-primary bg-white group-hover:border-examine-purple-400 sm:left-[135px]">
						<div className="inner" />
					</div>
				) : view === "preview" ? (
					<CollapsibleTrigger>
						<div className="absolute left-[-9px] top-1.5 box-border flex size-5 items-center justify-center rounded-full border-2 border-solid border-primary bg-white group-hover:bg-primary group-hover:text-white">
							{open ? (
								<Minus className="size-3 xl:size-3.5" strokeWidth="1.5" />
							) : (
								<Plus className="size-3 xl:size-3.5" strokeWidth="1.5" />
							)}
						</div>
					</CollapsibleTrigger>
				) : null}

				<div className="flex-[0_1_100%] pb-8 pl-8">
					<CollapsibleTrigger className="text-start">
						<div
							className={cn(
								view === "full" ? "pt-4 sm:hidden" : view === "preview" ? "pt-0.5" : ""
							)}
						>
							<Tooltip>
								<TooltipTrigger asChild>
									<span className={cn(view === "preview" ? "text-sm" : "", "cursor-default")}>
										{formatTiming({
											date,
											pattern: category == "study_summary" ? "MMMM d, yyyy" : "MMMM d, yyyy h:mma",
										})}
									</span>
								</TooltipTrigger>
								<TooltipContent>
									<p className="font-semibold">
										{format(
											new Date(date),
											category == "study_summary" ? "MMMM d, yyyy" : "MMMM d, yyyy h:mma"
										)}
									</p>
								</TooltipContent>
							</Tooltip>
						</div>
						<h3
							className={cn(
								"mt-3 flex flex-wrap items-center gap-2 pb-1.5 align-middle text-xl font-semibold",
								view === "preview" ? "mt-1 text-base" : ""
							)}
						>
							{content?.name || notes ? (
								<Link
									href={content?.url || ""}
									className="inline text-primary hover:underline [&>*]:inline"
									key={id}
								>
									<span>{content?.name}</span>
									<span>&nbsp;-&nbsp;</span>
									{parse(notes)}
								</Link>
							) : null}
							{headline ? (
								headline_link ? (
									<Link
										href={headline_link || ""}
										className="inline text-primary hover:underline [&>*]:inline"
										key={id}
									>
										{parse(headline)}
									</Link>
								) : (
									<div className="inline cursor-default text-primary [&>*]:inline">
										{parse(headline)}
									</div>
								)
							) : null}

							<div
								className={cn(
									"flex items-center gap-1 overflow-hidden",
									view === "preview" ? "hidden" : ""
								)}
							>
								{type ? (
									<span
										className={cn(
											"rounded-full px-2 py-[3px] font-sans text-xs font-semibold capitalize",
											type === "minor" && "bg-examine-purple-200 ",
											type === "correction" && "bg-green-500 text-white",
											type === "major" && "bg-examine-purple-500 text-white"
										)}
									>
										{type}
									</span>
								) : null}

								{category ? (
									<span
										className={cn(
											"rounded-full bg-examine-purple-500 px-2 py-[3px] font-sans text-xs font-semibold capitalize text-white"
										)}
									>
										{category.replace(/_/g, " ")}
									</span>
								) : null}
							</div>
						</h3>
					</CollapsibleTrigger>
					<CollapsibleContent>
						<section className="content text-base font-medium text-gray-600 [&>ul]:list-disc">
							<AccessCheck
								feature={["study_summaries", "examine_database", "supplement_guides"]}
								cta={
									<>
										{parse(detailed_note)}
										{parse(note)}
									</>
								}
							>
								{parse(basic_note)}
								{parse(note)}
								{category != "study_summary" && detailed_note ? (
									<>
										<br />
										<Alert variant="info" className="flex gap-2 px-4">
											<span>
												<LockBadge size={9} />
											</span>
											<div className="text-sm lg:text-base">
												Detailed update notes are provided for Examine+ members.{" "}
												<LoginLink className="text-examine-purple-400 hover:underline">
													Log in
												</LoginLink>
												<FreeTrialWrapper cta=" to read the full update notes">
													{" "}
													or{" "}
													<SaleWrapper
														cta={
															<ExaminePlusLink
																trackDetails={{
																	trackFor: "trial",
																	location: "Updates section on Homepage",
																	label: "Try Examine+ free for 7 days",
																}}
																className="text-examine-purple-400 hover:underline"
															>
																try Examine+ free for <Trial />
															</ExaminePlusLink>
														}
													>
														<ExaminePlusLink
															trackDetails={{
																trackFor: "sale",
																location: "Updates section on Homepage",
																label: `Get Examine+ for ${percentOff} off`,
															}}
															className="text-examine-purple-400 hover:underline"
														>
															Get Examine+ for {percentOff} off{" "}
														</ExaminePlusLink>
													</SaleWrapper>
												</FreeTrialWrapper>
												.
											</div>
										</Alert>
									</>
								) : null}
							</AccessCheck>
							{category === "study_summary" ? (
								<ul className="!mt-0 list-disc">
									{study_summaries?.map((summary) => (
										<li key={summary.headline_link} className="cursor-default !p-0">
											{summary?.headline_link ? (
												<Link
													href={summary.headline_link + "?requirelogin=1"}
													className="inline-flex cursor-pointer hover:underline"
												>
													{parse(summary.headline)}
												</Link>
											) : (
												parse(summary.headline)
											)}
										</li>
									))}
								</ul>
							) : null}
						</section>
					</CollapsibleContent>
				</div>
			</article>
		</Collapsible>
	);
};

export { SinglePageUpdate, AuthorList };
export default Update;
